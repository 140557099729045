<template>
                        <div class="grid">

<div class="col-12 lg:col-6 xl:col-2">
    <div v-on:click="go_to_user_rider()">
        <div class="card mb-0" style="background-color:#A3CC88">
            <div class="flex justify-content-between mb-1">
                <div>
                    <span class="block  font-medium mb-1">Commuters</span>
                    <div class="text-900 font-medium text-xl">{{ dashcount.riders }}</div>
                </div>
                <div class="flex align-items-center justify-content-center bg-blue-100 border-round"
                    style="width:2.5rem;height:2.5rem">
                    <i class="pi pi-fw pi-users text-blue-500 text-xl"></i>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="col-12 lg:col-6 xl:col-2">
    <div v-on:click="go_to_driver()">
        <div class="card mb-0" style="background-color:#F8C100">
            <div class="flex justify-content-between mb-1">
                <div>
                    <span class="block  font-medium mb-1">Drivers</span>
                    <div class="text-900 font-medium text-xl">{{ dashcount.drivers }}</div>
                </div>
                <div class="flex align-items-center justify-content-center bg-orange-100 border-round"
                    style="width:2.5rem;height:2.5rem">
                    <i class="pi pi-fw pi-users text-orange-500 text-xl"></i>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="col-12 lg:col-6 xl:col-2">
    <div v-on:click="go_to_vehicle()">
        <div class="card mb-0" style="background-color:#BDD7EE">
            <div class="flex justify-content-between mb-1">
                <div>
                    <span class="block  font-medium mb-1">Vehicles</span>
                    <div class="text-900 font-medium text-xl">{{ dashcount.vehicles }}</div>
                </div>
                <div class="flex align-items-center justify-content-center bg-cyan-100 border-round"
                    style="width:2.5rem;height:2.5rem">
                    <i class="pi pi-car text-cyan-500 text-xl"></i>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="col-12 lg:col-6 xl:col-2">
    <div v-on:click="goto_alert()">
        <div class="card mb-0" style="background-color:#BDD7EE">
            <div class="flex justify-content-between mb-1">
                <div>
                    <span class="block  font-medium mb-1">Alerts</span>
                    <div class="text-900 font-medium text-xl">{{ dashcount.pending_alerts }}
                    <!-- <hr><span class="text-red-500 font-small">100 New alert</span> -->
                    </div>
                    
                
                </div>
                <div class="flex align-items-center justify-content-center bg-purple-100 border-round"
                    style="width:2.5rem;height:2.5rem">
                    <i class="bell pi pi-bell text-red-500 text-xl" v-if="dashcount.pending_alerts>0"></i>
                    <i class="pi pi-bell text-red-500 text-xl" v-else></i>
                </div>
                
                    
                
                
                <!-- <span class="bell pi pi-bell text-red-500 text-xl"></span> -->
            </div>
        </div>
    </div>

</div>
<!-- <div class="col-12 lg:col-6 xl:col-1"></div> -->
<!-- &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; -->
<div class="col-12 lg:col-6 xl:col-2">
    <div v-on:click="go_to_rider()">
        <div class="card mb-0" style="background-color:#8396B1">
            <div class="flex justify-content-between mb-1">
                <div>
                    <span class="block  font-medium mb-1">Travels</span>
                    <div class="text-900 font-medium text-xl">{{ dashcount.travels }}</div>
                </div>
                <div class="flex align-items-center justify-content-center bg-purple-100 border-round"
                    style="width:2.5rem;height:2.5rem">
                    <i class="pi pi-map-marker text-purple-500 text-xl"></i>
                </div>
            </div>
        </div>
    </div>

</div>
<div class="col-12 lg:col-6 xl:col-2">
    <div v-on:click="go_to_route()">
        <div class="card mb-0" style="background-color:#F8C100">
            <div class="flex justify-content-between mb-1">
                <div>
                    <span class="block  font-medium mb-1">Routes</span>
                    <div class="text-900 font-medium text-xl">{{ dashcount.routes }}</div>
                </div>
                <div class="flex align-items-center justify-content-center bg-purple-100 border-round"
                    style="width:2.5rem;height:2.5rem">
                    <i class="pi pi-directions text-yellow-500 text-xl"></i>
                </div>
            </div>
        </div>
    </div>

</div>
<!-- <div class="col-12 lg:col-6 xl:col-2">
    <div v-on:click="go_to_route()">
        <div class="card mb-0" style="background-color:#b062ad">
            <div class="flex justify-content-between mb-1">
                <div>
                    <span class="block  font-medium mb-1">Clients</span>
                    <div class="text-900 font-medium text-xl">{{ dashcount.clients }}</div>
                </div>
                <div class="flex align-items-center justify-content-center bg-purple-100 border-round"
                    style="width:2.5rem;height:2.5rem">
                    <i class="pi pi-directions text-purple-500 text-xl"></i>
                </div>
            </div>
        </div>
    </div>

</div>
<div class="col-12 lg:col-10 xl:col-10"></div> -->

<div class="col-12 lg:col-4 xl:col-4">
    <div v-on:click="go_to_subject()">
        <div class="card mb-0  mt-3" style="">
            <div class="flex justify-content-between mb-3">
                <div>
                    <h5 class="align-self-start">Users</h5>
                </div>
                <div>
                    <div class="flex align-items-center justify-content-center bg-purple-100 border-round"
                        style="width:2.5rem;height:2.5rem">
                        <i class="pi pi-users text-purple-500 text-xl"></i>
                    </div>
                </div>
            </div>
            <hr>
            <Chart type="doughnut" :data="pieData" :options="pieOptions" />
        </div>
    </div>
    <div v-on:click="go_to_vehicle()">
        <div class="card mb-0  mt-3" style="">
            <div class="flex justify-content-between mb-3">
                <div>
                    <h5 class="align-self-start">Vehicles</h5>
                </div>
                <div>
                    <div class="flex align-items-center justify-content-center bg-red-100 border-round"
                        style="width:2.5rem;height:2.5rem">
                        <i class="pi pi-car text-purple-500 text-xl"></i>
                    </div>
                </div>
            </div>
            <hr>
            <Chart type="pie" :data="pieData1" :options="pieOptions" />
        </div>
    </div>
</div>



<div class="col-12 lg:col-8">
    <div class="card mb-0  mt-3">
        <div class="flex justify-content-between mb-3">
        <div>
        <h5>Last 30 day's Ride Report</h5></div>
        <div>
                    <div class="flex align-items-center justify-content-center bg-purple-100 border-round"
                        style="width:2.5rem;height:2.5rem">
                        <i class="pi pi-car text-purple-500 text-xl"></i>
                    </div>
                </div>
            </div>
        <hr>
        <Chart type="bar" :data="barData" :options="barOptions" />
    </div>
    <div class="card mb-0  mt-3" >
        <div class="flex justify-content-between mb-3">
            <div>
                <h5 class="align-self-start">Alerts</h5>
            </div>
            <div>
                <div class="flex align-items-center justify-content-center bg-red-300 border-round"
                    style="width:2.5rem;height:2.5rem">
                    <i class="pi pi-bell text-red-700 text-xl"></i>
                </div>
            </div>
        </div>
        <!-- <hr>
        <div class="flex justify-content-between mb-3">
            <div>
                Today's Alerts
            </div>
            <div>
                <div class="flex align-items-center justify-right-right" style="width:100px;height:2.5rem">
                    <div style="width:50px;text-align:right;margin-right:20px;color:red;font-size:18px">
                        <b>{{ dashcount.today_alerts }}</b>
                    </div>
                    <div><Button @click="goto_alert()" icon="pi pi-arrow-right "
                            style="background-color:grey;width:30px;height:30px"
                            class=" mr-2 mb-2 pi-button-sm"></Button>
                    </div>
                </div>
            </div>
        </div> -->
        <hr>
        <div class="flex justify-content-between mb-3">
            <div>
                Total Alerts
            </div>
            <div>
                <div class="flex align-items-center justify-right-right" style="width:100px;height:2.5rem">
                    <div style="width:50px;text-align:right;margin-right:20px;color:red;font-size:18px">
                        <b>{{ dashcount.alerts }}</b>
                    </div>
                    <div><Button @click="goto_alert()" icon="pi pi-arrow-right "
                            style="background-color:grey;width:30px;height:30px"
                            class="mr-2 mb-2 pi-button-sm"></Button>
                    </div>
                </div>
            </div>
        </div>
        <hr>

    </div>
</div>

</div>
</template>
<script>
import apis from "@/apis";
import axios from "axios";
import EventBus from '@/AppEventBus';

export default {
    data() {
		return {
            isLoadingModel:false,
            fullPage:true,
            user_key: "",
            client_id_dropdown: [],
            client_id_search: '',
            routeitem: '',
            routeitems: [],
            
            pieOptions: null,
            tabs: ['Dashboard', 'Live Ride Tracking Dashboard'],

            selectedTab: "Dashboard",
            pieData: {},
            pieData1: {},
            center: { lat: 19.0626704, lng: 73.005094 },
            center1: {},
            centerstart: {},
            polyline: [
            ],
            polyline1: [
            ],
            polypath: {
               
            },

            search_key: "",
            home: true,
            random: 0,
            token: '',
            get_date: "",
            get_date_2: "",
            locations1: [],
            locations: [
                { lat: 19.0626704, lng: 73.005094, name: "Prathamesh" },
                { lat: 28.38, lng: 77.12, name: "Sanjay" }

            ],
            markers: [
                {
                    position: [18.5314, 73.845],
                    draggable: true,
                    title: "Marker title"
                }]
            ,
            messages: [],
            inputMessageText: "",

            form: {
                roomName: "63a939f2b319a2f01eb75798",
                userName: "639c6caae0aa678e9f6de543",
            },
            products: null,
            todayslectures: [],

            dashcount: {
                "riders": 0,
                "drivers": 0,
                "driverowner": 0,
                "vehicles": 0,
                "owners": 0,
                "admin": 0,
                "travels": 0,
                "schools": 0,
                "alerts": 0,
                "today_alerts": 0,
                "today_travels": 0,
                "school_buses": 0,
                "pending_alerts":0,
                "routes":0,
                "clients":0
            },
            // center: [37,7749, -122,4194],

            lineData: {
                labels: ['0', '0 to 20', '20 to 40', '40 to 60', '60 to 80', '80 to 100'],
                datasets: [
                    {
                        label: 'Network',
                        data: [],
                        backgroundColor: '#00bb7e',

                    },
                    
                ]
            },
            travellist: [],
            travellist_filter: [],
            lastNumber: 1,
          
            barData: {
                labels: [],
                height: "150px",
                datasets: [
                    {
                        label: 'Total Rides',
                        backgroundColor: '#2f4860',
                        data: []
                    }
                ]
            },
            barData1: {
                labels: [],
                datasets: [
                    {
                        label: 'No Of Lectures by Teacher',
                        backgroundColor: '#2f4860',
                        data: []
                    }
                ]
            },
            barOptions: null,
            barOptions1: null,
            items: [
                { label: 'Add New', icon: 'pi pi-fw pi-plus' },
                { label: 'Remove', icon: 'pi pi-fw pi-minus' }
            ],
            lineOptions: null,
          
      
        }    
    },
    methods:
    {
        async dashboardcount() {
            var data = {};
            var promise = apis.dashboardcount(data);
            promise
                .then(response => {

                    var other_data = response.data.other_data;
                    this.dashcount.today_travels = other_data.todays_travel;

                    this.dashcount.travels = other_data.total_travel;
                    this.dashcount.schools = other_data.schools;
                    this.dashcount.school_buses = other_data.school_buses;
                    this.dashcount.alerts = other_data.alerts;
                    this.dashcount.today_alerts = other_data.todays_alerts;
                    this.dashcount.vehicles = other_data.vehicles;
                    this.dashcount.routes=other_data.routes;
                    this.dashcount.clients=other_data.clients;

                    var data = response.data.data;




                    for (var i = 0; i < data.length; i++) {

                        if (data[i].user_type == 'Driver') {
                            this.dashcount.drivers += data[i].count;
                        }
                        if (data[i].user_type == 'Owner') {
                            this.dashcount.owners += data[i].count;
                        }
                        if (data[i].user_type == 'Rider') {
                            this.dashcount.riders += data[i].count;
                        }
                        if (data[i].user_type == 'Driver Owner') {
                            this.dashcount.driverowner = data[i].count;
                        }
                        if (data[i].user_type == 'Admin') {
                            this.dashcount.admin = data[i].count;
                        }

                    }
                    // if(i==(count-1))
                    // {
                    // alert('here');
                    // alert(this.dashcount.riders);
                    // alert(i);
                    this.dashcount.alerts = response.data.other_data.alerts;
                    this.dashcount.today_alerts = response.data.other_data.todays_alerts;
                    this.pieData = {
                        labels: response.data.other_data.user_counts.labels,
                        datasets: [
                            {
                                data: response.data.other_data.user_counts.series_data,
                                backgroundColor: [
                                    "#F04136",
                                    "#2B3890",
                                    "#F7941E",
                                    "#F9ED32",
                                    "#D7F422",
                                    "#DE6BBC",
                                    "#DEDD59",
                                    "#58DEDF"
                                ],
                                hoverBackgroundColor: [
                                    "#FF6384",
                                    "#36A2EB",
                                    "#FFCE56",
                                    "#662D91",
                                    "#F8F194",
                                    "#58DEDF",
                                    "#949597",
                                    "#F8F194"

                                ]
                            }
                        ]
                    };
                    this.pieData1 = {
                        labels: response.data.other_data.vehicle_group.labels,
                        datasets: [
                            {
                                data: response.data.other_data.vehicle_group.series_data,
                                backgroundColor: [
                                    "#F04136",
                                    "#2B3890",
                                    "#F7941E",
                                    "#39B54A",
                                    "#D7F422",
                                    "#DE6BBC",
                                    "#DEDD59",
                                    "#58DEDF"
                                ],
                                hoverBackgroundColor: [
                                    "#FF6384",
                                    "#36A2EB",
                                    "#FFCE56",
                                    "#662D91",
                                    "#F8F194",
                                    "#58DEDF",
                                    "#949597",
                                    "#DEDD59"
                                ]
                            }
                        ]
                    };
                    // }


                    // this.dashcount=response.data;
                });

        },
        go_to_user_rider()
        {
            this.$router.push('/usermaster');
            localStorage.driver = 'Rider';
        },
        go_to_driver()
        {
            this.$router.push('/usermaster');
            localStorage.driver = 'Driver';
           
        },
        go_to_vehicle() {
            this.$router.push('/vehiclemaster');
        },
        goto_alert() {
            this.$router.push('/sosalertmaster');
            localStorage.alert = 'alert';
        },
        go_to_rider() {
            this.$router.push('/ridemaster');
        },
        go_to_route()
        {
            this.$router.push('/groupmaster');
        },
        go_to_subject() {
            this.$router.push('/usermaster');
        },
       go_to_Notice() {
            this.$router.push('/NoticeMaster');
        },


        async dashboardRideData() {
            var data = { client_id: localStorage.getItem("client_id") ? localStorage.getItem("client_id") : '' };
            var promise = apis.dashboardRideData(data);
            promise
                .then(response => {
                    this.barData.labels = response.data.data.series;
                    this.barData.datasets[0].data = response.data.data.data;
                });
        },
        get_pending_alert_count() {
      var data = {
        id: localStorage.getItem("id"),
        count: true,
        unread: false,
        mobile_number:'',
        status: 0,
        from_date: new Date().toISOString().split('T')[0],
        to_date: new Date().toISOString().split('T')[0],
        type:"ride sos alert",
        client_id: localStorage.getItem("client_id")
      };
     
      var promise = apis.sosAlertMaster(data);
      promise.then((response) => {
   
        console.log(response);
        this.dashcount.pending_alerts = response.data.data;
       
      });

    },
    isDarkTheme() {
            return this.$appState.darkTheme === true;
        },
        applyLightTheme() {
            this.barOptions = {
                plugins: {
                    legend: {
                        labels: {
                            color: '#495057'
                        }
                    },

                },
                scales: {
                    x: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef',
                        }
                    },
                    y: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef',
                        }
                    },
                }
            };
            this.barOptions1 = {
                indexAxis: 'y',
                plugins: {
                    legend: {
                        labels: {
                            color: '#495057'
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef',
                        }
                    },
                    y: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef',
                        }
                    },
                }
            };
            this.lineOptions = {
                plugins: {
                    legend: {
                        labels: {
                            color: '#495057'
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef',
                        }
                    },
                    y: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef',
                        }
                    },
                }
            };
        },
        applyDarkTheme() {
            this.lineOptions = {
                plugins: {
                    legend: {
                        labels: {
                            color: '#ebedef'
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            color: '#ebedef'
                        },
                        grid: {
                            color: 'rgba(160, 167, 181, .3)',
                        }
                    },
                    y: {
                        ticks: {
                            color: '#ebedef'
                        },
                        grid: {
                            color: 'rgba(160, 167, 181, .3)',
                        }
                    },
                }
            };
        }
        
    },
    async created()
    {
       
        await this.dashboardcount();
        await this.get_pending_alert_count();
        await this.dashboardRideData();
      
    
    },
    async mounted() {
        this.themeChangeListener = (event) => {
            if (event.dark)
                this.applyDarkTheme();
            else
                this.applyLightTheme();
        };
        EventBus.on('change-theme', this.themeChangeListener);

        if (this.isDarkTheme()) {
            this.applyDarkTheme();
        }
        else {
            this.applyLightTheme();
        }
    },
    beforeUnmount() {
        EventBus.off('change-theme', this.themeChangeListener);
    },
}
</script>
<style scoped>



.bell{
  display:block;
  /* width: 40px;
  height: 40px; */
  /* font-size: 40px; */
  /* margin:50px auto 0; */
  color: #9e9e9e;
  -webkit-animation: ring 4s .7s ease-in-out infinite;
  -webkit-transform-origin: 50% 4px;
  -moz-animation: ring 4s .7s ease-in-out infinite;
  -moz-transform-origin: 50% 4px;
  animation: ring 4s .2s ease-in-out infinite;
  transform-origin: 50% 4px;
}

@-webkit-keyframes ring {
  0% { -webkit-transform: rotateZ(0); }
  1% { -webkit-transform: rotateZ(30deg); }
  3% { -webkit-transform: rotateZ(-28deg); }
  5% { -webkit-transform: rotateZ(34deg); }
  7% { -webkit-transform: rotateZ(-32deg); }
  9% { -webkit-transform: rotateZ(30deg); }
  11% { -webkit-transform: rotateZ(-28deg); }
  13% { -webkit-transform: rotateZ(26deg); }
  15% { -webkit-transform: rotateZ(-24deg); }
  17% { -webkit-transform: rotateZ(22deg); }
  19% { -webkit-transform: rotateZ(-20deg); }
  21% { -webkit-transform: rotateZ(18deg); }
  23% { -webkit-transform: rotateZ(-16deg); }
  25% { -webkit-transform: rotateZ(14deg); }
  27% { -webkit-transform: rotateZ(-12deg); }
  29% { -webkit-transform: rotateZ(10deg); }
  31% { -webkit-transform: rotateZ(-8deg); }
  33% { -webkit-transform: rotateZ(6deg); }
  35% { -webkit-transform: rotateZ(-4deg); }
  37% { -webkit-transform: rotateZ(2deg); }
  39% { -webkit-transform: rotateZ(-1deg); }
  41% { -webkit-transform: rotateZ(1deg); }

  43% { -webkit-transform: rotateZ(0); }
  100% { -webkit-transform: rotateZ(0); }
}

@-moz-keyframes ring {
  0% { -moz-transform: rotate(0); }
  1% { -moz-transform: rotate(30deg); }
  3% { -moz-transform: rotate(-28deg); }
  5% { -moz-transform: rotate(34deg); }
  7% { -moz-transform: rotate(-32deg); }
  9% { -moz-transform: rotate(30deg); }
  11% { -moz-transform: rotate(-28deg); }
  13% { -moz-transform: rotate(26deg); }
  15% { -moz-transform: rotate(-24deg); }
  17% { -moz-transform: rotate(22deg); }
  19% { -moz-transform: rotate(-20deg); }
  21% { -moz-transform: rotate(18deg); }
  23% { -moz-transform: rotate(-16deg); }
  25% { -moz-transform: rotate(14deg); }
  27% { -moz-transform: rotate(-12deg); }
  29% { -moz-transform: rotate(10deg); }
  31% { -moz-transform: rotate(-8deg); }
  33% { -moz-transform: rotate(6deg); }
  35% { -moz-transform: rotate(-4deg); }
  37% { -moz-transform: rotate(2deg); }
  39% { -moz-transform: rotate(-1deg); }
  41% { -moz-transform: rotate(1deg); }

  43% { -moz-transform: rotate(0); }
  100% { -moz-transform: rotate(0); }
}

@keyframes ring {
  0% { transform: rotate(0); }
  1% { transform: rotate(30deg); }
  3% { transform: rotate(-28deg); }
  5% { transform: rotate(34deg); }
  7% { transform: rotate(-32deg); }
  9% { transform: rotate(30deg); }
  11% { transform: rotate(-28deg); }
  13% { transform: rotate(26deg); }
  15% { transform: rotate(-24deg); }
  17% { transform: rotate(22deg); }
  19% { transform: rotate(-20deg); }
  21% { transform: rotate(18deg); }
  23% { transform: rotate(-16deg); }
  25% { transform: rotate(14deg); }
  27% { transform: rotate(-12deg); }
  29% { transform: rotate(10deg); }
  31% { transform: rotate(-8deg); }
  33% { transform: rotate(6deg); }
  35% { transform: rotate(-4deg); }
  37% { transform: rotate(2deg); }
  39% { transform: rotate(-1deg); }
  41% { transform: rotate(1deg); }

  43% { transform: rotate(0); }
  100% { transform: rotate(0); }
}
</style>